<template>
  <div id="dashboardVehicle"  class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Dashboard Vehicle Tracking</div>
        <div class="box-S1-3 noPadding">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field class="hideMessage input-date" v-model="picker" label="Select Date" readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              type="month"
              @change="Render(picker)"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>  
    <div id="Dashboard" class="wrap-Main">
      <div class="box-S2">
        <div class="B-carddetail">
          <p class="left T-size-20 noMargin">{{inDTO_InternalKM.countjob}} Job</p>
          <p class="right T-size-20 noMargin">{{inDTO_InternalKM.countdistance}} KM.</p>
        </div>
      </div>
      <div class="box-S2">
        <div class="B-carddetail">
          <p class="left T-size-20 noMargin">{{inDTO_ClientKM.countjob}} Job</p>
          <p class="right T-size-20 noMargin">{{inDTO_ClientKM.countdistance}} KM.</p>
        </div>
      </div>
      <div class="box-S2">
        <highcharts :options="chartOptionsSumtotal"></highcharts>
      </div>
      <div class="box-S2">
        <highcharts :options="chartOptionSumOver"></highcharts>
      </div>
      <div class="box-S2">
        <highcharts :options="chartOptionSumHour"></highcharts>
      </div>
      <div class="box-S2">
        <highcharts :options="chartOptionsPIEClient"></highcharts>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import feathersClientVan from "../plugins/feathers-client";
import { color } from "highcharts";

export default {
  data: () => ({
    chartOptionsPIEClient: {},
    chartOptionsSumtotal: {},
    chartOptionSumOver: {},
    chartOptionSumHour: {},
    inDTO: "",
    inDTO_OverSpeed: "",
    inDTO_SumHour: "",

    inDTO_ClientKM: "",
    inDTO_InternalKM: "",

    menu: false,
    dateSelect: new Date().toISOString().substr(0, 7),
    picker: {}
  }),
  components: {
    highcharts: Chart
  },
  async mounted() {
    this.picker = this.dateSelect;
    this.chartSumTotal();
    this.chartSumOver();
    this.chartSumhour();
    this.chartSumClient();
    this.KM();
  },
  methods: {
    Render(Select) {
      this.chartSumTotal(Select);
      this.chartSumOver(Select);
      this.chartSumhour(Select);
      this.chartSumClient(Select);
      this.KM(Select);
    },
    async chartSumTotal(Select) {
      const q = {};

      if (Select == null) {
        q.date = this.dateSelect;
      } else {
        q.date = Select;
      }

      //alert(JSON.stringify(q))

      let inDTOdata = await feathersClientVan
        .service("sumtotalbydate")
        .find({ query: q });
      this.inDTO = inDTOdata;
    
      this.chartOptionsSumtotal = {
        chart: {
          type: "column",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Sum by date",
          style: {
            color: "#ffffff"
          }
        },
        xAxis: {
          type: "datetime",
          labels: {
            style: {
              color: "#ffffff"
            },
            format: "{value:%Y-%m-%d}",
            rotation: 45,
            align: "left"
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total fruit consumption",
            style: {
              color: "#ffffff"
            }
          },
          stackLabels: {
            enabled: true,
            style: {
              color: "#ffffff"
            }
          },
          gridLineColor: "#616161"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          },
          align: "right",
          x: -30,
          verticalAlign: "top",
          y: 25,
          floating: true,
          backgroundColor: "#333232",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: true
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          },
          series: {
            borderColor: "#333232"
          }
        },
        series: this.inDTO
      };
    },
    async chartSumOver(Select) {
      const q = {};

      if (Select == null) {
        q.date = this.dateSelect;
      } else {
        q.date = Select;
      }

      //alert(JSON.stringify(q))

      let inDTOdata = await feathersClientVan
        .service("sumoverspeed")
        .find({ query: q });
      this.inDTO_OverSpeed = inDTOdata;

      this.chartOptionSumOver = {
        chart: {
          type: "column",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Sum Over Speed",
          style: {
            color: "#ffffff"
          }
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%Y-%m-%d}",
            rotation: 45,
            align: "left",
            style: {
              color: "#ffffff"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total fruit consumption",
            style: {
              color: "#ffffff"
            }
          },
          stackLabels: {
            enabled: true,
            style: {
              color: "#ffffff"
            }
          },
          gridLineColor: "#616161"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          },
          align: "right",
          x: -30,
          verticalAlign: "top",
          y: 25,
          floating: true,
          backgroundColor: "#333232",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          },
          series: {
            borderColor: "#333232"
          }
        },
        series: this.inDTO_OverSpeed
      };
    },
    async chartSumhour(Select) {
      const q = {};

      if (Select == null) {
        q.date = this.dateSelect;
      } else {
        q.date = Select;
      }

      //alert(JSON.stringify(q))

      let inDTOdata = await feathersClientVan
        .service("sumhourbydriver")
        .find({ query: q });
      this.inDTO_SumHour = inDTOdata;

      this.chartOptionSumHour = {
        chart: {
          type: "line",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Sum Hour",
          style: {
            color: "#ffffff"
          }
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%Y-%m-%d}",
            rotation: 45,
            align: "left",
            style: {
              color: "#ffffff"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total fruit consumption",
            style: {
              color: "#ffffff"
            }
          },
          stackLabels: {
            enabled: true,
            style: {
              color: "#ffffff"
            }
          },
          gridLineColor: "#616161"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          },
          align: "right",
          x: -30,
          verticalAlign: "top",
          y: 25,
          floating: true,
          backgroundColor: "#333232",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          },
          series: {
            borderColor: "#333232"
          }
        },
        series: this.inDTO_SumHour
      };
    },
    async chartSumClient(Select) {
      const q = {};

      if (Select == null) {
        q.date = this.dateSelect;
      } else {
        q.date = Select;
      }

      let inDTOdata = await feathersClientVan
        .service("sumhourbydriver")
        .find({ query: q });
      this.inDTO_SumHour = inDTOdata;

      this.chartOptionsPIEClient = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Browser market shares in January, 2018",
          style: {
            color: "#ffffff"
          }
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          }
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: [
              {
                name: "Internal",
                y: 40
              },
              {
                name: "CPOC",
                y: 60
              }
            ],
            borderColor: "#333232"
          }
        ]
      };
    },
    async KM(Select) {
      const q = {};

      if (Select == null) {
        q.date = this.dateSelect;
      } else {
        q.date = Select;
      }

      let inDTOdata = await feathersClientVan
        .service("sumdistance")
        .find({ query: q });
      this.inDTO_ClientKM = inDTOdata[0];
      this.inDTO_InternalKM = inDTOdata[0];
    }
  }
};
</script>

<style>
</style>